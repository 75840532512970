import { graphql } from "gatsby";
import * as React from "react";
import ContentsMenu from "../components/ContentsMenu";
import Layout from "../components/layout";
import theme from "../styles/theme";

const TechDescPage = ({ location, data }) => {
  const seo = {
    tags: [
      {
        tagName: "title",
        content: "Guides and Descriptions",
      },
    ],
  };

  return (
    <Layout location={location} seo={seo} logo={data.global.logo}>
      <main
        css={`
          margin: 0 auto 100px;
          padding-bottom: 0;
          min-height: 100vh;
          width: 100%;
          max-width: 1600px;
          box-sizing: border-box;
          padding-top: 10px;
          ${theme.tablet} {
            padding-top: 40px;
          }

          .intro {
            svg {
              margin-top: 30px;
            }
          }
          h1 {
            margin-top: 100px;
            font-size: 64px;
            margin-bottom: 20px;
            max-width: 600px;
            ${theme.laptop} {
              font-size: 58px;
              margin-top: 80px;
            }
            ${theme.tablet} {
              margin-top: 10px;
            }
            ${theme.mobile} {
              font-size: 36px;
              margin-top: 10px;
            }
          }
          p {
            max-width: 950px;
            font-size: 18px;
            line-height: 120%;
            ${theme.mobile} {
              font-size: 18px;
            }
          }
        `}
      >
        <section
          css={`
            margin-top: 100px;
            flex-direction: column;
            margin-bottom: 20px;
          `}
        >
          <div className="intro">
            <h1>{data.page.title}</h1>
            <p>{data.page.lead}</p>
          </div>
        </section>
        <section
          css={`
            margin-top: 60px;
            flex-wrap: wrap;
            flex-direction: column;
            .row {
              display: flex;
              flex-wrap: wrap;
            }
            .row .topic:first-of-type {
              padding-right: 20px;
            }
            .topic {
              display: flex;
              flex: 1 1 280px;
              flex-direction: column;
              box-sizing: border-box;
              h2 {
                color: #474747;
                font-size: 32px;
                ${theme.mobile} {
                  font-size: 22px;
                }
              }
            }
            a.cardLink {
              box-sizing: border-box;
              height: 60px;
              width: 100%;
              display: flex;
              align-items: center;
              box-shadow: 0px 4px 8px 6px rgba(197, 197, 197, 0.25);
              border-radius: 10px;
              margin-bottom: 15px;
              &:hover {
                box-shadow: 0px 4px 10px 8px rgba(197, 197, 197, 0.35);
              }
              p {
                margin: 0;
                font-size: 16px;
                &.mb {
                  margin-bottom: 16px;
                }
              }
              svg {
                width: 30px;
                margin-left: 20px;
                margin-right: 20px;
              }
            }
            a.cardLinkLarge {
              height: auto !important;
              padding-top: 30px;
              padding-bottom: 30px;
              padding-right: 30px;
              svg {
                width: 50px;
                margin-left: 40px;
                margin-right: 40px;
                ${theme.mobile} {
                  width: 30px;
                  margin-left: 20px;
                  margin-right: 20px;
                }
              }
            }
          `}
        >
          <div className="row">
            <div className="topic">
              <h2>{data.page.iTitle}</h2>
              <div>
                {data.page.iLinks.map(item => {
                  return (
                    <a
                      className="cardLink"
                      href={item.url}
                      key={item.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <SvgPdf />
                      <p>{item.title}</p>
                    </a>
                  );
                })}
              </div>
            </div>
            <div className="topic">
              <h2>{data.page.iiTitle}</h2>
              <div>
                {data.page.iiLinks.map(item => {
                  return (
                    <a
                      className="cardLink"
                      href={item.url}
                      key={item.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <SvgPdf />
                      <p>{item.title}</p>
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
          <div>
            <div className="topic">
              <h2>{data.page.iiiTitle}</h2>
              <div>
                {data.page.iiiLinks.map(item => {
                  return (
                    <a
                      className="cardLink cardLinkLarge"
                      href={item.url}
                      key={item.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div>
                        <SvgBackend />
                      </div>
                      <div>
                        <p className="mb">
                          <strong>{item.title}</strong>
                        </p>
                        <p>{item.description}</p>
                      </div>
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default TechDescPage;

export const query = graphql`
  query TechDocPageQuery {
    page: datoCmsGuidesDoc {
      title
      lead
      iTitle
      iLinks {
        title
        url
      }
      iiTitle
      iiLinks {
        title
        url
      }
      iiiTitle
      iiiLinks {
        url
        title
        description
      }
    }
    global: datoCmsGlobal {
      logo {
        fluid(maxWidth: 700) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
    }
  }
`;

const SvgPdf = () => {
  return (
    <svg viewBox="0 0 52 55" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.6778 12.7739H12.3055C11.015 12.7739 9.96875 13.766 9.96875 14.9896C9.96875 16.2133 11.015 17.2054 12.3055 17.2054H20.6778C21.9683 17.2054 23.0145 16.2133 23.0145 14.9896C23.0145 13.766 21.9683 12.7739 20.6778 12.7739Z"
        fill="#EC008C"
      />
      <path
        d="M20.6778 21.9814H12.3055C11.015 21.9814 9.96875 22.9735 9.96875 24.1972C9.96875 25.4208 11.015 26.4129 12.3055 26.4129H20.6778C21.9683 26.4129 23.0145 25.4208 23.0145 24.1972C23.0145 22.9735 21.9683 21.9814 20.6778 21.9814Z"
        fill="#EC008C"
      />
      <path
        d="M30.502 37.4953V39.6531H31.7041C33.1621 39.6531 33.1621 38.826 33.1621 38.5542C33.1621 38.292 33.1621 37.4951 31.7041 37.4951H30.502V37.4953Z"
        fill="#EC008C"
      />
      <path
        d="M48.2515 33.4183H44.4488V10.9585C44.4488 10.3774 44.2058 9.82037 43.7721 9.40987L34.6003 0.712465C34.1667 0.302115 33.58 0.0715332 32.9671 0.0715332H5.36503C2.86427 0.0715332 0.830078 2.00053 0.830078 4.37179V50.4726C0.830078 52.844 2.86443 54.773 5.36503 54.773H39.9137C42.4143 54.773 44.4487 52.8439 44.4487 50.4726V46.0341H48.2513C50.2277 46.0341 51.8301 44.5149 51.8301 42.6407V36.8116C51.8302 34.9377 50.2279 33.4183 48.2515 33.4183ZM39.524 49.985H5.75479V4.85954H29.7588V11.2963C29.7588 12.8554 31.0914 14.1187 32.735 14.1187H39.524V33.4183H27.9684C25.9919 33.4183 24.3897 34.9377 24.3897 36.8117V42.6408C24.3897 44.515 25.9919 46.0343 27.9684 46.0343H39.524V49.985ZM34.641 38.5545C34.641 40.0967 33.5156 41.055 31.7045 41.055H30.5024V42.381C30.5024 42.7231 30.21 43.0009 29.8489 43.0009H29.678C29.3167 43.0009 29.0242 42.7231 29.0242 42.381V36.7138C29.0242 36.3717 29.3167 36.0938 29.678 36.0938H31.7045C33.5158 36.0937 34.641 37.0364 34.641 38.5545ZM38.3478 43.0009H35.9963C35.6351 43.0009 35.3425 42.7231 35.3425 42.381V36.7138C35.3425 36.3717 35.6349 36.0938 35.9963 36.0938H38.3478C40.396 36.0938 42.0624 37.6448 42.0624 39.5517C42.0624 41.4536 40.3962 43.0009 38.3478 43.0009ZM47.7181 36.8754C47.7181 37.2181 47.4255 37.4953 47.0643 37.4953H44.409V38.9803H46.6711C47.032 38.9803 47.3249 39.2573 47.3249 39.6V39.7623C47.3249 40.1044 47.032 40.3823 46.6711 40.3823H44.409V42.3808C44.409 42.7229 44.1166 43.0008 43.7553 43.0008H43.5846C43.2232 43.0008 42.9308 42.7229 42.9308 42.3808V36.7136C42.9308 36.3715 43.2232 36.0937 43.5846 36.0937H47.0643C47.4255 36.0937 47.7181 36.3715 47.7181 36.7136V36.8754Z"
        fill="#EC008C"
      />
      <path
        d="M38.3494 37.4956H36.8223V41.5994H38.3494C39.5824 41.5994 40.5856 40.6803 40.5856 39.5518C40.5858 38.4179 39.5826 37.4956 38.3494 37.4956Z"
        fill="#EC008C"
      />
    </svg>
  );
};

const SvgBackend = () => {
  return (
    <svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M56.6667 53.3333L43.3333 40V29.4C47.2 28 50 24.3333 50 20C50 14.4667 45.5333 10 40 10C34.4667 10 30 14.4667 30 20C30 24.3333 32.8 28 36.6667 29.4V40L23.3333 53.3333H10V70H26.6667V59.8333L40 45.8333L53.3333 59.8333V70H70V53.3333H56.6667Z"
        fill="#E00084"
      />
    </svg>
  );
};
